import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import TrackersAPI from '../../api/TrackersAPI.js';

export default function AppHistoryModal({
    open,
    setHistoryModalOpen,
    appNum,
    applicationOwnerEditOptions,
    allActiveUsers,
    ercAppStatusEditOptions,
    qaReviewStatusEditOptions,
    qaSubmitterEditOptions,
    taskOwnerEditOptions,
}) {
    const [historyType, setHistoryType] = React.useState('App Changes');
    const [form471AppChanges, setForm471AppChanges] = React.useState([]);
    const [form471TaskUserChanges, setForm471TaskUserChanges] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('field_name');
    const trackersAPI = new TrackersAPI();

    React.useEffect(() => {
        const getChangeEngineData = async (appNum) => {
            setIsLoading(true);
            console.log('WE ARE HERE');
            const apiResponse = await trackersAPI.GetForm471AppChangeEngineData(appNum);
            if (apiResponse && apiResponse.statusCode === 200) {
                const parsedData = JSON.parse(apiResponse.body);
                setForm471AppChanges(parsedData.form471_app_changes);
                setForm471TaskUserChanges(parsedData.form471_task_user_changes);
            }
            setIsLoading(false);
        };

        if (appNum) {
            getChangeEngineData(appNum);
        }
    }, [appNum]);

    const areAllListsEmpty = () => {
        return (
            Array.isArray(form471AppChanges) &&
            form471AppChanges.length === 0 &&
            Array.isArray(form471TaskUserChanges) &&
            form471TaskUserChanges.length === 0
        );
    };

    const isCurrentTypeEmpty = () => {
        switch (historyType) {
            case 'App Changes':
                return Array.isArray(form471AppChanges) && form471AppChanges.length === 0;
            case 'Task User Changes':
                return Array.isArray(form471TaskUserChanges) && form471TaskUserChanges.length === 0;
            default:
                return false;
        }
    };

    const formatCustomDate = (dateString) => {
        if (!dateString) return '-';
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    const formatDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'MM/dd/yyyy @ HH:mm:ss') : '-';
    };

    const getUserFullName = (value) => {
        //console.log("APP DATA: ", applicationOwnerEditOptions);
        if (!allActiveUsers || allActiveUsers.length === 0) {
            console.log('allActiveUsers is not populated yet.');
            return value;
        }
        // type check or you'll just get a number instead of the actual user object
        const user = allActiveUsers.find((user) => String(user.value) === String(value));
        return user ? user.text : value;
    };

    const getStatusText = (value) => {
        const status = ercAppStatusEditOptions.find((status) => status.value === parseInt(value));
        return status ? status.text : value;
    };

    const getTextFromSelectOptions = (selectOptions, value) => {
        const option = selectOptions.find((option) => option.value === parseInt(value));
        return option ? option.text : value;
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const resetState = () => {
        setHistoryModalOpen(false);
        setHistoryType('App Changes');
        setForm471AppChanges([]);
        setForm471TaskUserChanges([]);
        setOrder('asc');
        setOrderBy('field_name');
        setIsLoading(true);
    };

    return (
        <Modal
            open={open}
            onClose={resetState}
            aria-labelledby='history-modal-title'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    boxShadow: 24,
                    p: 4,
                    width: '70%',
                    maxHeight: '50%',
                    overflow: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ minWidth: 150 }}>
                        <Select value={historyType} onChange={(e) => setHistoryType(e.target.value)} size='small'>
                            <MenuItem value='App Changes'>App Changes</MenuItem>
                            <Divider sx={{ my: 0, padding: 0 }} />
                            <MenuItem value='Task User Changes'>Task User Changes</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography
                        id='history-modal-title'
                        variant='h6'
                        sx={{
                            fontWeight: 'bold',
                            flex: 1,
                            textAlign: 'center',
                            mx: 2,
                            fontSize: '1.2rem',
                        }}
                    >
                        Change History for Form471 #<u>{appNum}</u>
                    </Typography>

                    <IconButton
                        onClick={resetState}
                        sx={{
                            color: 'grey.500',
                            '&:hover': {
                                color: 'red',
                                bgcolor: 'transparent',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {isLoading ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 8 }}>
                        <CircularProgress />
                        <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                            <em>Gathering change history data...</em>
                        </Typography>
                    </Box>
                ) : areAllListsEmpty() ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                        <Typography variant='body1' sx={{ color: 'gray' }}>
                            No change history found
                        </Typography>
                    </Box>
                ) : isCurrentTypeEmpty() ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                        <Typography variant='body1' sx={{ color: 'gray' }}>
                            No {historyType} History
                        </Typography>
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                    {historyType === 'Task User Changes' && <TableCell>Task User</TableCell>}
                                    <TableCell sortDirection={orderBy === 'field_name' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'field_name'}
                                            direction={orderBy === 'field_name' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'field_name')}
                                        >
                                            Field Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Old Value</TableCell>
                                    <TableCell>New Value</TableCell>
                                    <TableCell sortDirection={orderBy === 'modified_on' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'modified_on'}
                                            direction={orderBy === 'modified_on' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'modified_on')}
                                        >
                                            Modified On
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'modified_by' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'modified_by'}
                                            direction={orderBy === 'modified_by' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'modified_by')}
                                        >
                                            Modified By
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {historyType === 'App Changes' &&
                                    stableSort(
                                        form471AppChanges.filter(
                                            (change) =>
                                                ![
                                                    'record_updated_timestamp',
                                                    'user_entered_field_updated_timestamp',
                                                    'user_entered_field_updated_by_user_id',
                                                ].includes(change.field_name)
                                        ),
                                        getComparator(order, orderBy)
                                    ).map((change) => (
                                        <TableRow key={change.id}>
                                            <TableCell>
                                                {change.field_name === 'application_owner_user_id'
                                                    ? 'application_owner'
                                                    : change.field_name}
                                            </TableCell>
                                            <TableCell>
                                                {change.field_name === 'application_owner_user_id'
                                                    ? getUserFullName(change.old_value)
                                                    : [
                                                          'record_updated_timestamp',
                                                          'user_entered_field_updated_timestamp',
                                                      ].includes(change.field_name)
                                                    ? formatDate(change.old_value)
                                                    : change.field_name === 'user_entered_field_updated_by_user_id'
                                                    ? getUserFullName(change.old_value)
                                                    : change.field_name === 'erc_form471_app_status_id'
                                                    ? getStatusText(change.old_value)
                                                    : change.old_value || '-'}
                                            </TableCell>
                                            <TableCell>
                                                {change.field_name === 'application_owner_user_id'
                                                    ? getUserFullName(change.new_value)
                                                    : [
                                                          'record_updated_timestamp',
                                                          'user_entered_field_updated_timestamp',
                                                      ].includes(change.field_name)
                                                    ? formatDate(change.new_value)
                                                    : change.field_name === 'user_entered_field_updated_by_user_id'
                                                    ? getUserFullName(change.new_value)
                                                    : change.field_name === 'erc_form471_app_status_id'
                                                    ? getStatusText(change.new_value)
                                                    : change.new_value || '-'}
                                            </TableCell>
                                            <TableCell>{formatDate(change.modified_on)}</TableCell>
                                            <TableCell>{getUserFullName(change.modified_by)}</TableCell>
                                        </TableRow>
                                    ))}
                                {historyType === 'Task User Changes' &&
                                    stableSort(
                                        form471TaskUserChanges.filter(
                                            (change) =>
                                                ![
                                                    'form471_app_num',
                                                    'record_created_timestamp',
                                                    'record_updated_timestamp',
                                                ].includes(change.field_name)
                                        ),
                                        getComparator(order, orderBy)
                                    ).map((change) => (
                                        <TableRow key={change.id}>
                                            <TableCell>
                                                {getTextFromSelectOptions(taskOwnerEditOptions, change.task_user_id)}
                                            </TableCell>
                                            <TableCell>{change.field_name}</TableCell>
                                            <TableCell>
                                                {change.field_name === 'date_assigned_to_task'
                                                    ? formatCustomDate(change.old_value)
                                                    : ['record_updated_timestamp', 'record_created_timestamp'].includes(
                                                          change.field_name
                                                      )
                                                    ? formatDate(change.old_value)
                                                    : change.old_value || '-'}
                                            </TableCell>
                                            <TableCell>
                                                {change.field_name === 'date_assigned_to_task'
                                                    ? formatCustomDate(change.new_value)
                                                    : ['record_updated_timestamp', 'record_created_timestamp'].includes(
                                                          change.field_name
                                                      )
                                                    ? formatDate(change.new_value)
                                                    : change.field_name === 'user_id'
                                                    ? getTextFromSelectOptions(taskOwnerEditOptions, change.new_value)
                                                    : change.new_value || '-'}
                                            </TableCell>
                                            <TableCell>{formatDate(change.modified_on)}</TableCell>
                                            <TableCell>{getUserFullName(change.modified_by)}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Modal>
    );
}
