import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import logo from '../media/ERateCentralLogo.png';

export default function ClientContactSearchReportEngine(props) {
    const clientAPI = props.clientAPI;
    const [ben, setBen] = React.useState('');
    const [contactName, setContactName] = React.useState('');
    const [schoolLibraryName, setSchoolLibraryName] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [allStates, setAllStates] = React.useState([]);
    const [zip, setZip] = React.useState('');
    const [showWarning, setShowWarning] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [benSearchLoading, setBenSearchLoading] = React.useState(false);
    const [attributesSearchLoading, setAttributesSearchLoading] = React.useState(false);
    const [contactData, setContactData] = React.useState([]);
    const [showResults, setShowResults] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const getStates = async () => {
            const allStatesVar = await clientAPI.GetStates();
            setAllStates(allStatesVar);
            console.log('[ClientContactSearchReportEngine][useEffect][getStates] allStatesVar:', allStatesVar);
            return allStatesVar;
        };

        getStates();
    }, []);

    React.useEffect(() => {
        if (state) {
            const hasOtherCriteria = contactName !== '' || schoolLibraryName !== '' || city !== '' || zip !== '';
            setShowWarning(!hasOtherCriteria);
        } else {
            setShowWarning(false);
        }
    }, [state, contactName, schoolLibraryName, city, zip]);

    const searchForContacts = async () => {
        try {
            let apiResponse;
            if (ben && ben !== '-') {
                setBenSearchLoading(true);
                apiResponse = await clientAPI.ClientListSearchForContacts({ ben: ben });
            } else {
                setAttributesSearchLoading(true);
                apiResponse = await clientAPI.ClientListSearchForContacts({
                    ben: '',
                    contactName: contactName,
                    schoolLibraryName: schoolLibraryName,
                    city: city,
                    state: state,
                    zip: zip,
                });
            }
            console.log('[ClientContactSearchReportEngineResults][searchForContacts] apiResponse:', apiResponse);

            if (apiResponse) {
                setContactData(apiResponse);
                setShowResults(true);
            }
        } catch (error) {
            console.error(
                '[ClientContactSearchReportEngineResults][searchForContacts] Error searching for contacts:',
                error
            );
        } finally {
            setBenSearchLoading(false);
            setAttributesSearchLoading(false);
        }
    };

    const returnToSearch = () => {
        setShowResults(false);
        // setBen('');
        // setContactName('');
        // setSchoolLibraryName('');
        // setCity('');
        // setState('');
        // setZip('');
    };

    const groupContactsByBEN = (data) => {
        if (!data || !Array.isArray(data) || data.length === 0) return [];

        // Sorting the entire dataset by funding year descending
        const sortedData = [...data].sort((a, b) => parseInt(b.fundingyear) - parseInt(a.fundingyear));

        // Grouping all data objects by BEN
        const groupedByBEN = sortedData.reduce((acc, contact) => {
            const ben = contact.billedentitynumber;
            if (!acc[ben]) {
                acc[ben] = [];
            }
            acc[ben].push(contact);
            return acc;
        }, {});

        // Processing each BEN group
        const result = Object.values(groupedByBEN).map((benGroup) => {
            // Sorting by funding year descending to get most recent first
            const sortedGroup = [...benGroup].sort((a, b) => parseInt(b.fundingyear) - parseInt(a.fundingyear));

            // Getting the most recent contact data
            const mostRecent = sortedGroup[0];

            // Generating consultant entries for 2012 - current year
            const currentYear = new Date().getFullYear();
            const consultantsByYear = [];

            for (let year = currentYear; year >= 2012; year--) {
                const contactForYear = sortedGroup.find((contact) => parseInt(contact.fundingyear) === year);

                consultantsByYear.push({
                    year,
                    consultingfirmsname: contactForYear?.consultingfirmsname || null,
                    crn: contactForYear?.crn || null,
                    fundingyear: contactForYear?.fundingyear || year.toString(),
                });
            }

            return {
                contactInfo: mostRecent,
                consultants: consultantsByYear,
            };
        });

        // Sorting the final results by funding year descending
        return result.sort((a, b) => parseInt(b.contactInfo.fundingyear) - parseInt(a.contactInfo.fundingyear));
    };

    const getSearchCriteriaText = (params) => {
        const criteria = [];
        if (params.ben && params.ben !== '') criteria.push(`BEN: ${params.ben}`);
        if (params.contactName && params.contactName !== '') criteria.push(`Contact Name: ${params.contactName}`);
        if (params.schoolLibraryName && params.schoolLibraryName !== '')
            criteria.push(`School/Library Name: ${params.schoolLibraryName}`);
        if (params.city && params.city !== '') criteria.push(`City: ${params.city}`);
        if (params.state && params.state !== '') criteria.push(`State: ${params.state}`);
        if (params.zip && params.zip !== '') criteria.push(`ZIP: ${params.zip}`);
        return criteria;
    };

    const handleBenChange = (event) => {
        setBen(event.target.value);
    };

    const handleContactNameChange = (event) => {
        setContactName(event.target.value);
    };

    const handleSchoolLibraryNameChange = (event) => {
        setSchoolLibraryName(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event);
    };

    const handleZipChange = (event) => {
        setZip(event.target.value);
    };

    return (
        <>
            {!showResults ? (
                <>
                    <Typography variant='h2' align='center'>
                        E-Rate Organizer Contact Search
                    </Typography>
                    <Container maxWidth='md'>
                        {/* Search by BEN */}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                                            <Typography variant='h5' gutterBottom align='left' color='textSecondary'>
                                                Search by Billed Entity Number (BEN)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component='th' scope='row' style={{ borderBottom: 'none' }}>
                                            Billed Entity Number
                                        </TableCell>
                                        <TableCell style={{ borderBottom: 'none' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    variant='outlined'
                                                    size='small'
                                                    placeholder='Enter BEN...'
                                                    value={ben}
                                                    onChange={handleBenChange}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ borderBottom: 'none' }} />
                                        <TableCell style={{ borderBottom: 'none' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    mt: -2,
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                {benSearchLoading && (
                                                    <>
                                                        <CircularProgress size={24} />
                                                        <Typography variant='body2' color='textSecondary'>
                                                            Gathering contact data...
                                                        </Typography>
                                                    </>
                                                )}
                                                <Button
                                                    variant='contained'
                                                    color='success'
                                                    onClick={searchForContacts}
                                                    sx={{ backgroundColor: '#4CAF50' }}
                                                    disabled={benSearchLoading}
                                                >
                                                    Search
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography
                            variant='h4'
                            align='center'
                            style={{
                                margin: '20px 0',
                                fontWeight: 'bold',
                            }}
                        >
                            OR
                        </Typography>

                        {/* Search by other attributes */}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                                            <Typography variant='h5' gutterBottom align='left' color='textSecondary'>
                                                Search by any combination of contact name, school/library name, city,
                                                state, and/or ZIP
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component='th' scope='row'>
                                            Contact Name
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                size='small'
                                                placeholder='Enter contact name...'
                                                value={contactName}
                                                onChange={handleContactNameChange}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell component='th' scope='row'>
                                            School/Library Name
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                size='small'
                                                placeholder='Enter school/library name...'
                                                value={schoolLibraryName}
                                                onChange={handleSchoolLibraryNameChange}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell component='th' scope='row'>
                                            City
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                size='small'
                                                placeholder='Enter city name...'
                                                value={city}
                                                onChange={handleCityChange}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell component='th' scope='row'>
                                            State
                                        </TableCell>
                                        <TableCell style={{ width: '60.503%' }}>
                                            {' '}
                                            {/* Adjusted to stop the state select expansion bug */}
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id='state-search-label'>State</InputLabel>
                                                <Select
                                                    labelId='state-search-label'
                                                    id='state-search'
                                                    value={state}
                                                    label='State'
                                                    onChange={(event) => handleStateChange(event.target.value)}
                                                >
                                                    <MenuItem value=''>{'<blank>'}</MenuItem>
                                                    {allStates?.length > 0
                                                        ? allStates.map((row, i) => (
                                                              <MenuItem value={row.abbreviation} key={row.abbreviation}>
                                                                  {row.name}
                                                              </MenuItem>
                                                          ))
                                                        : null}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell component='th' scope='row' style={{ borderBottom: 'none' }}>
                                            ZIP
                                        </TableCell>
                                        <TableCell style={{ borderBottom: 'none' }}>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                size='small'
                                                placeholder='Enter ZIP code...'
                                                value={zip}
                                                onChange={handleZipChange}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell style={{ borderBottom: 'none' }} />
                                        <TableCell style={{ borderBottom: 'none' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    mt: -2,
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                {attributesSearchLoading && (
                                                    <>
                                                        <CircularProgress size={24} />
                                                        <Typography variant='body2' color='textSecondary'>
                                                            Gathering contact data...
                                                        </Typography>
                                                    </>
                                                )}
                                                {showWarning && !attributesSearchLoading && (
                                                    <Typography
                                                        variant='body2'
                                                        color='warning.main'
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                        }}
                                                    >
                                                        ⚠️ Please add at least one more search criteria
                                                    </Typography>
                                                )}
                                                <Button
                                                    variant='contained'
                                                    color='success'
                                                    onClick={searchForContacts}
                                                    sx={{ backgroundColor: '#4CAF50' }}
                                                    disabled={attributesSearchLoading || showWarning}
                                                >
                                                    Search
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </>
            ) : (
                <Container maxWidth='false'>
                    {benSearchLoading || attributesSearchLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '80vh',
                                gap: 2,
                            }}
                        >
                            <CircularProgress size={40} />
                            <Typography variant='body1' color='textSecondary'>
                                Gathering contact data...
                            </Typography>
                        </Box>
                    ) : !contactData || contactData.length === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '80vh',
                                gap: 2,
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant='h4'
                                sx={{
                                    color: 'red',
                                    fontWeight: 500,
                                }}
                                gutterBottom
                            >
                                No contact data found for:
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {getSearchCriteriaText({ ben, contactName, schoolLibraryName, city, state, zip }).map(
                                    (criteria, index) => {
                                        const [label, value] = criteria.split(': ');
                                        return (
                                            <Typography
                                                key={index}
                                                variant='body1'
                                                sx={{
                                                    color: 'text.primary',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {label}:{' '}
                                                <Box
                                                    component='span'
                                                    sx={{
                                                        color: 'text.secondary',
                                                        textDecoration: 'underline',
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {value}
                                                </Box>
                                            </Typography>
                                        );
                                    }
                                )}
                                <Button
                                    variant='contained'
                                    color='success'
                                    onClick={() => {
                                        navigate('/reports/contact-search-engine');
                                        window.location.reload();
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        fontSize: '1rem',
                                        backgroundColor: '#4CAF50',
                                    }}
                                >
                                    New Search
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: '65px',
                                    }}
                                >
                                    <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                                        <img
                                            height='100px'
                                            src={logo}
                                            alt='Logo'
                                            style={{ cursor: 'pointer', marginTop: '-90px' }}
                                        />
                                    </Link>
                                    <Link
                                        sx={{
                                            fontSize: '0.875rem',
                                            marginRight: '38%',
                                            marginTop: '-5%',
                                            cursor: 'pointer',
                                        }}
                                        href='https://e-ratecentral.com'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {'https://e-ratecentral.com'}
                                    </Link>
                                </Box>
                            </Box> */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mt: 3,
                                    mb: 4,
                                }}
                            >
                                <Typography
                                    variant='h2'
                                    sx={{
                                        color: 'text.primary',
                                        fontWeight: 500,
                                    }}
                                >
                                    E-Rate Organizer Contact Search
                                </Typography>

                                <Button
                                    variant='contained'
                                    color='success'
                                    onClick={returnToSearch}
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        fontSize: '1rem',
                                        backgroundColor: '#4CAF50',
                                    }}
                                >
                                    New Search
                                </Button>
                            </Box>

                            <TableContainer
                                component={Paper}
                                sx={{
                                    mt: 4,
                                    width: '100%',
                                    '& .MuiTable-root': {
                                        width: '100%',
                                    },
                                    '& .MuiTableCell-root': {
                                        verticalAlign: 'top',
                                    },
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                            <TableCell>Contact Person</TableCell>
                                            <TableCell>Contact Information</TableCell>
                                            <TableCell>Organization Information</TableCell>
                                            <TableCell>Funding Year</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupContactsByBEN(contactData).map((benGroup, groupIndex) => (
                                            <TableRow key={groupIndex}>
                                                <TableCell>
                                                    <Typography variant='body2'>
                                                        {benGroup.contactInfo?.contactname || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                                                        <Typography variant='body2'>
                                                            {benGroup.contactInfo?.contactaddress1}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {`${benGroup.contactInfo?.contactcity}, ${
                                                                benGroup.contactInfo?.contactstate
                                                            } ${benGroup.contactInfo?.contactzip}${
                                                                benGroup.contactInfo?.contactzipext
                                                                    ? '-' + benGroup.contactInfo?.contactzipext
                                                                    : ''
                                                            }`}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {`Phone: ${benGroup.contactInfo?.contactphone}${
                                                                benGroup.contactInfo?.contactphoneext
                                                                    ? ' Ext ' + benGroup.contactInfo?.contactphoneext
                                                                    : ''
                                                            }`}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Email:{' '}
                                                            {benGroup.contactInfo?.contactemail ? (
                                                                <Link
                                                                    href={`mailto:${benGroup.contactInfo.contactemail}`}
                                                                    rel='noreferrer'
                                                                    sx={{
                                                                        textDecoration: 'none',
                                                                        fontWeight: 'normal',
                                                                    }}
                                                                >
                                                                    {benGroup.contactInfo.contactemail}
                                                                </Link>
                                                            ) : (
                                                                <span>N/A</span>
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                                                            <Typography variant='subtitle2'>
                                                                {benGroup.contactInfo.billedentityname}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {benGroup.contactInfo.billedentityaddress1}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {`${benGroup.contactInfo.billedentitycity}, ${
                                                                    benGroup.contactInfo.billedentitystate
                                                                } ${benGroup.contactInfo.billedentityzipcode}${
                                                                    benGroup.contactInfo.billedentityzipcodeext
                                                                        ? '-' +
                                                                          benGroup.contactInfo.billedentityzipcodeext
                                                                        : ''
                                                                }`}
                                                            </Typography>
                                                        </Box>

                                                        <Typography variant='body2'>
                                                            BEN:{' '}
                                                            <Link
                                                                component='button'
                                                                onClick={() =>
                                                                    searchForContacts(
                                                                        benGroup.contactInfo.billedentitynumber
                                                                    )
                                                                }
                                                                sx={{
                                                                    textDecoration: 'none',
                                                                    cursor: 'pointer',
                                                                    color: 'primary.main',
                                                                    '&:hover': {
                                                                        textDecoration: 'underline',
                                                                    },
                                                                }}
                                                            >
                                                                {benGroup.contactInfo.billedentitynumber}
                                                            </Link>
                                                        </Typography>

                                                        <div>
                                                            {benGroup.consultants.map((consultant, yearIndex) => (
                                                                <Box
                                                                    key={yearIndex}
                                                                    sx={{
                                                                        mb: 0.25,
                                                                        display: 'flex',
                                                                        alignItems: 'flex-start',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant='body2'
                                                                        color='textSecondary'
                                                                        sx={{ mr: 1 }}
                                                                    >
                                                                        Consultant {consultant.year}:
                                                                    </Typography>
                                                                    <Box>
                                                                        {consultant.consultingfirmsname ? (
                                                                            <Link
                                                                                href={`/client-list/consulting-firms-applicant-report/${consultant.fundingyear}/${consultant.crn}`}
                                                                                rel='noreferrer'
                                                                                sx={{
                                                                                    textDecoration: 'none',
                                                                                    fontWeight: 'normal',
                                                                                    fontSize: '0.875rem',
                                                                                    color: 'primary.main',
                                                                                    '&:hover': {
                                                                                        textDecoration: 'underline',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {consultant.consultingfirmsname}
                                                                            </Link>
                                                                        ) : (
                                                                            <Typography
                                                                                variant='body2'
                                                                                color='textSecondary'
                                                                                sx={{ fontSize: '0.875rem' }}
                                                                            >
                                                                                No consultant found
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </div>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2'>{`FY: ${benGroup.contactInfo.fundingyear}`}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Container>
            )}
        </>
    );
}
